import { createSlice } from "@reduxjs/toolkit";
import { getSubjects } from "./SubjectThunk";

export interface Subject {
  photo: string;
  private: string;
  _id: string;
  name: string;
  status: boolean;
  updatedAt: string;
  createdAt: string;
}
export interface Teacher {
  firstName: string;
  lastName: string;
  photo?: string;
}
export interface Session {
  _id: string;
  name: string;
}
export interface Result {
  subject: Subject;
  progress: number;
  teacher: Teacher;
  session: Session;
  sectionId: string;
}
interface SubjectSliceType {
  data: Result[];
  loading: boolean;
  error: string | null | unknown;
}
interface Response extends Teacher {
  subject: Subject;
  sessions: Session;
  progress: number;
  sectionId: string;
}
export const initialState: SubjectSliceType = {
  data: [],
  loading: false,
  error: null,
};

export const SubjectSlice: any = createSlice({
  name: "subject",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubjects.pending, (state, { payload }) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getSubjects.fulfilled, (state, { payload }) => {
        state.error = null;
        state.loading = false;
        state.data = payload?.data?.payload?.map((item: Response) => {
          const { createdAt, updatedAt, ...subject } = item?.subject;
          const progress = item?.progress;
          const sectionId = item?.sectionId;
          const session = item?.sessions;
          const teacher = {
            firstName: item?.firstName,
            lastName: item?.lastName,
            photo: item?.photo,
          };

          return {
            subject,
            progress,
            teacher,
            sectionId,
            session,
          };
        });
      })
      .addCase(getSubjects.rejected, (state, { payload }) => {
        state.error = true;
        state.loading = false;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = SubjectSlice.actions;

export const reducer = SubjectSlice.reducer;
