const fr = {
  auth: {
    login: "Connectez-vous",
    firstName_required: "Le nom est requis",
    lastName_required: "Le prénom est requis",
    mail_required: "L'e-mail est requis",
    mail_valid: "L'e-mail doit être une adresse e-mail valide",
    password_required: "Mot de passe requis",
    forgot_password: "Mot de passe oublié",
    sign_in: "Se connecter",
    no_account: "Vous n’avez pas un compte?",
    have_account: "Vous avez déjà un compte?",
    sign_up: "S’inscrire",
    signUp: "Inscription",
    teacher: "Professeur",
    student: "étudiant(e)",
    create_account: "Créez un compte",
    mail: "E-mail",
    first_name: "Nom",
    last_name: "Prénom",
    state: "Gouvernorat",
    state_required: "Le gouvernement est requis",
    establishement: "Établissement",
    establishement_required: "L'établissement est requis",
    class: "Classe",
    password: "Mot de passe",
    password_verification: "Vérification du mot de passe",
    verification: "verification",
    no_code: "Vous n'avez pas reçu le code?",
    resend: "Renvoyez",
    verify_account: "Vérifiez votre E-mail",
    enter_code: "Entrer le code ici",
    verify: "Vérifier",
    invalid_code: "Code invalide",
    slogan_first_row: "Une seule plateforme ",
    slogan_second_row: "pour tous vos examens",
    verification_before_email:
      "Veuillez entrer le code de vérification envoyé à",
    verification_after_email: "Le code expire dans",
    forgot_password_question: "Mot de passe oublié?",
    forget_password_email_desc:
      "Veuillez entrer votre e-mail pour réinitialiser votre mot de passe.",
    continue: "Continuer",
    update_password: "Réinitialiser le mot de passe",
    create_new_password: "Créer un nouveau mot de passe",
    password_match: "Les mots de passe ne correspondent pas",
    welcome_to_ixamee: "Bienvenue à Ixamee",
    lets_create_your_account: "Créons votre compte",
    been_invited_by: "Vous avez été invité par",
    subject: "Matière",
    expired_code: "le code est expiré",
    your_mail_is: "L’adresse e-mail de votre compte est",
    expired_invitation: "cette invitation est expirée",
    accept_invitation: "Accepter l’invitation",
    oldPassword: "Mot de passe actuel",
    newPassword: "Nouveau mot de passe",
    confirmNewPassword: "Confirmez le nouveau mot de passe",
    passwordLength: "The password consists of 8 characters.",
    confirmPasswordMismatch:
      "Le mot de passe doit correspondre à la confirmation du mot de passe.",
  },
  correction: {
    correction: "Correction",
  },
  bulletin: {
    bulletin: "Bulletin",
    pdf_download: "Télécharger en pdf",
    exam: "Examen",
    added_on: "Ajouté le",
    range: "Rang",
    general_grade: "Moyenne génerale",
    session: "Session",
    grade: "Note",
    no_exam: "Aucun Examen",
  },
  sidebar: {
    dashboard: "Tableau de bord",
    results: "Mes résultats",
    classes: "Classe",
    calendar: "Calendrier",
  },
  header: {
    logout: "Déconnexion",
  },
  demo: {
    title: `France`,
    introduction: `Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.`,
  },
  docs: {
    hi: `Bonjour`,
    description: `Besoin d'aide? \n Consultez notre documentation.`,
    documentation: `documentation`,
  },
  subject: {
    title: `Progression des examens`,
    mentor: `Professeur`,
    open: `ouvrir`,
  },
  dashboard: {
    emptyExam: `Pas d’examens pour le moment`,
    emptyMarkSheet: `Pas de bulletins pour le moment`,
    stats: `Statistiques examens`,
  },
  userInfo: {
    security: `Sécurité et authentification`,
    modify: `Modifier mot de passe`,
    annuler: `Annuler`,
    submit: `Modifier`,
  },
  app: `application`,
  user: `utilisateur`,
  list: `liste`,
  edit: `éditer`,
  shop: `boutique`,
  blog: `blog`,
  post: `poste`,
  mail: `e-mail`,
  chat: `discuter`,
  cards: `cartes`,
  posts: `des postes`,
  create: `créer`,
  kanban: `kanban`,
  general: `général`,
  banking: `bancaire`,
  booking: `réservation`,
  profile: `profil`,
  account: `compte`,
  product: `produit`,
  invoice: `facture`,
  details: `détail`,
  checkout: `vérifier`,
  calendar: `calendrier`,
  analytics: `analytique`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level: `niveau menu`,
  menu_level_2a: `niveau menu 2a`,
  menu_level_2b: `niveau menu 2b`,
  menu_level_3a: `niveau menu 3a`,
  menu_level_3b: `niveau menu 3b`,
  menu_level_4a: `niveau menu 4a`,
  menu_level_4b: `niveau menu 4b`,
  item_disabled: `élément désactivé`,
  item_label: `étiquette de l'article`,
  item_caption: `légende de l'article`,
  item_external_link: `lien externe de l'article`,
  description: `la description`,
  other_cases: `autres cas`,
  item_by_roles: `élément par rôles`,
  only_admin_can_see_this_item: `seul l'administrateur peut voir cet élément`,
};

export default fr;
