// form
import { useFormContext, Controller } from "react-hook-form";

// @mui
import {
  Radio,
  FormLabel,
  RadioGroup,
  FormControl,
  FormHelperText,
  RadioGroupProps,
  FormControlLabel,
} from "@mui/material";
import { ChangeEvent } from "react";
import { getFirstLetters } from "src/utils/firstLetters";

// ----------------------------------------------------------------------

type Props = RadioGroupProps & {
  name: string;
  options: { label: string; value: any }[];
  label?: string;
  spacing?: number;
  helperText?: React.ReactNode;
  handleChange?: (event: ChangeEvent) => void;
  openNav?: boolean;
  defaultValue?: string;
};

export default function RHFRadioGroup({
  row,
  name,
  label,
  options,
  spacing,
  helperText,
  handleChange,
  defaultValue,
  openNav,
  ...other
}: Props) {
  const { control } = useFormContext();

  const labelledby = label ? `${name}-${label}` : "";

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl component='fieldset'>
          {label && (
            <FormLabel
              component='legend'
              id={labelledby}
              sx={{ typography: "body2" }}
            >
              {label}
            </FormLabel>
          )}
          {options && (
            <RadioGroup
              {...field}
              aria-labelledby={labelledby}
              row={row}
              defaultValue={defaultValue || options[0]?.value}
              className={openNav ? 'radio_group' : ''}
              onChange={handleChange}
              {...other}
              sx={{ overflowY: "auto", flexWrap: "nowrap" }}
            >
              {options.map((option) => (
                <div key={option.value} className='radio_container'>
                  <FormControlLabel
                    value={option.value}
                    control={
                      <Radio
                        sx={{
                          color: "white",
                        }}
                      />
                    }
                    label={openNav ? option.label : null}
                    sx={{
                      margin: 0,
                      color: "white",
                      "&:not(:last-of-type)": {
                        mb: spacing || 0,
                      },
                      ...(row && {
                        mr: 0,
                        "&:not(:last-of-type)": {
                          mr: spacing || 2,
                        },
                      }),
                    }}
                  />
                  {!openNav && (
                    <p className='radio-letter'>
                      {getFirstLetters(option.label)}
                    </p>
                  )}
                </div>
              ))}
            </RadioGroup>
          )}

          {(!!error || helperText) && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
