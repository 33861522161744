import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from "react";

import axios from "../utils/axios";

import { isValidToken, setRefreshSession, setAccessSession } from "./utils";
import {
  ActionMapType,
  AuthStateType,
  AuthUserType,
  JWTContextType,
} from "./types";
import { useAppDispatch } from "src/hooks/redux";
import {
  setActiveClass,
  setClasses,
  setInitClasses,
} from "src/data/slices/classes/ClassesSlice";
import { FormValuesProps } from "src/sections/auth/AuthSignUpForm";
import { Verification } from "src/data/types";

enum Types {
  INITIAL = "INITIAL",
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  LOGOUT = "LOGOUT",
  VERIFY = "VERIFY",
  UPDATE_USER = "UPDATE_USER",
  ACCEPTINVI = "ACCEPTINVI",
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.UPDATE_USER]: {
    photo: string;

  };
  [Types.VERIFY]: {
    isAuthenticated: boolean;
  };
  [Types.LOGOUT]: undefined;
  [Types.ACCEPTINVI]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];
// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.ACCEPTINVI) {
    return {
      ...state,
      isInitialized: false,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.VERIFY) {
    return {
      ...state,
      isAuthenticated: action.payload.isAuthenticated,
    };
  }
  if (action.type === Types.UPDATE_USER) {
    return {
      ...state,
      user: { ...state.user, photo: action.payload.photo },
    };

  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      isAuthenticated: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const reduxDispatch = useAppDispatch();

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const refreshToken = localStorage.getItem("refreshToken");

      if (refreshToken && isValidToken(refreshToken)) {
        if (!accessToken || !isValidToken(accessToken)) {
          const response: any = await axios.post(
            "/auth/teacher/refresh-token",
            { refreshToken }
          );

          const access = response.data.data.accessToken;
          setAccessSession(access);
        }

        const response = await axios.get("/profile/me");
        const { filteredUser : user } = response.data.data.user;
        const classes = response.data.data.user.studentClasses.docs.map(
          ({
            deletedAt,
            ...rest
          }: {
            deletedAt: String;
            name: string;
            _id: string;
          }) => rest
        );

        const classe = localStorage.getItem("class");
        if (classe) {
          const parsedClass = JSON.parse(classe);
          const exist = classes.filter(
            (cl: any) => cl?._id === parsedClass?._id
          );
          if (exist?.length > 0) {
            reduxDispatch(setClasses(classes));
            reduxDispatch(setActiveClass(parsedClass));
          } else {
            reduxDispatch(setInitClasses(classes));
          }
        } else {
          reduxDispatch(setInitClasses(classes));
        }

        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        setRefreshSession(null);
        setAccessSession(null);
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const init = async () => {
      await initialize();
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = useCallback(async (email: string, password: string) => {
    const response = await axios.post("/auth/student/login", {
      email,
      password,
    });

    const { accessToken, refreshToken, filteredUser } = response.data.data;
    const user = filteredUser.filteredUser;
    reduxDispatch(setInitClasses(filteredUser.studentClasses.docs));
    dispatch({
      type: Types.LOGIN,
      payload: {
        isAuthenticated: user.verified,
        user,
      },
    });
    if (user?.verified) {
      setAccessSession(accessToken);
      setRefreshSession(refreshToken);
    } else {
      return { email: user?.email, accessToken, refreshToken };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /// VERIFICATION
  const verify = useCallback(async (data: Verification) => {
    const { isAuthenticated, ...rest } = data;
    await axios.post("/auth/student/verification-account", {
      ...rest,
    });
    dispatch({
      type: Types.VERIFY,
      payload: {
        isAuthenticated,
      },
    });
  }, []);
  /// updateUser
  const updateUser = async ({ onSuccess, setProgress, onProgress, fmData, onError }: any) => {
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event: any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      }
    };
    try {
      const res = await axios.patch(
        "/profile",
        fmData,
        config
      );
      const response = await axios.get("/profile/me")
      onSuccess("Ok");
      await dispatch({
        type: Types.UPDATE_USER,
        payload: {
          photo: response?.data?.data?.user?.filteredUser?.photo
        },
      });

    } catch (err) {
      console.error("Eroor: ", err);
      const error = new Error("Some error");
      onError({ err });
    }

  };
  ///FORGOT PASSWORD
  const forgotPassword = useCallback(async (email: string) => {
    await axios.post("/auth/student/forgot-password", {
      email,
    });
  }, []);
  const resetPassword = useCallback(
    async (data: {
      code: string;
      email: string;
      password: string;
      confirmPassword: string;
    }) => {
      await axios.patch("/auth/student/reset-password", {
        ...data,
      });
    },
    []
  );
  const updatePassword = useCallback(
    async (data: {
      oldPassword: string;
      password: string;
      confirmPassword: string;
    }) => {
      const response = await axios.patch("profile/updater-password", {
        ...data,
      });
      return response;
    },
    []
  );
  const acceptInvitation = useCallback(
    async (data: {
      studentId: string;
      token: string;
      password: string;
      confirmPassword: string;
    }) => {
      const { studentId, ...rest } = data;
      const response = await axios.patch(
        `/students/edit-student-password/${studentId}`,
        {
          ...rest,
        }
      );
      dispatch({
        type: Types.ACCEPTINVI,
      });
      setAccessSession(response.data.data.tokens.accessToken);
      setRefreshSession(response.data.data.tokens.refreshToken);
      const init = async () => {
        await initialize();
      };
      init();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  // REGISTER
  const register = useCallback(async (data: FormValuesProps) => {
    const response = await axios.post("/auth/student/register", {
      ...data,
    });
    const { accessToken, refreshToken, user } = response?.data?.data;
    dispatch({
      type: Types.REGISTER,
      payload: {
        user: user?.filteredUser,
      },
    });
    return { email: user.filteredUser.email, accessToken, refreshToken };
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    await axios.delete(`/auth/student/logout`);
    setAccessSession(null);
    setRefreshSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);
  //getInvitation
  const getInvitation = useCallback(
    async ({
      studentId,
      classId,
      teacherId,
      resetToken,
    }: {
      studentId: string;
      classId: string;
      teacherId: string;
      resetToken: string;
    }) => {
      const response = await axios.get(
        `/students/invitation-info/${studentId}/${classId}/${teacherId}/${resetToken}`
      );
      return response;
    },
    []
  );

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: "jwt",
      login,
      forgotPassword,
      resetPassword,
      verify,
      updateUser,
      register,
      logout,
      getInvitation,
      acceptInvitation,
      updatePassword,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      forgotPassword,
      resetPassword,
      verify,
      updateUser,
      logout,
      register,
      getInvitation,
      acceptInvitation,
      updatePassword,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
