import { createSlice } from "@reduxjs/toolkit";
import { getEvents } from "./calendarThunk";

export interface calendarType {
  data: any[];
  loading: boolean;
  error?: boolean | null;
}

export const initialState: calendarType = {
  data: [],
  loading: false,
  error: null,
};
export const calendar: any = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    emptyEvents(state, action) {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEvents.pending, (state, action) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.data = action?.payload?.data?.payload.map((payload: any) => ({
          ...payload,
          id: payload._id,
        }));
      })
      .addCase(getEvents.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      });
  },
});

export const { emptyEvents } = calendar.actions;

export const reducer = calendar.reducer;
