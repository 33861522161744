import { createSlice } from "@reduxjs/toolkit";
import { getSessions } from "./SessionThunk";

interface Category {
  _id: string;
  name: string;
}

export interface Semester {
  _id: string;
  name: string;
  categories: Category[];
  private: boolean;
  createdAt: string;
  updatedAt: string;
}

interface SessionSliceProps {
  data: Semester[] | [];
  loading: boolean;
  error: string | null | unknown;
}

export const initialState: SessionSliceProps = {
  data: [],
  loading: false,
  error: null,
};

export const SessionSlice: any = createSlice({
  name: "session",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSessions.pending, (state, { payload }) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getSessions.fulfilled, (state, { payload }) => {
        state.error = null;
        state.loading = false;
        state.data = payload?.data?.payload?.docs;
      })
      .addCase(getSessions.rejected, (state, { payload }) => {
        state.error = true;
        state.loading = false;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = SessionSlice.actions;

export const reducer = SessionSlice.reducer;
