import { ReactNode, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { useAppSelector } from "src/hooks/redux";
import { PATH_DASHBOARD } from "src/routes/paths";

function ChangeClassHandler({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const { activeClass } = useAppSelector((state) => state?.classes);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      navigate(PATH_DASHBOARD.results.root);
    } else {
      isFirstRender.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeClass]);
  return <>{children}</>;
}

export default ChangeClassHandler;
