import React from 'react';
const AuthCheckbox = (props: any) => {
  const { label, id, labelStyle, onChange, ...rest } = props;
  return (
    <div className="auth-checkbox">
      <input onChange={onChange} defaultChecked type="checkbox" id={id} />
      <label htmlFor={id} className={labelStyle}>
        {label}
      </label>
    </div>
  );
};

export default AuthCheckbox;
