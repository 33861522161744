import { combineReducers, Reducer, ReducersMapObject } from "@reduxjs/toolkit";
import { reducer as classesReducer } from "../slices/classes/ClassesSlice";
import { reducer as dashboardReducer } from "../slices/dashboard/DashboardSlice";
import { reducer as sessionReducer } from "../slices/session/SessionSlice";
import { reducer as SubjectReducer } from "../slices/subject/SubjectSlice";
import { reducer as correctionReducer } from "../slices/correction/correctionSlice";
import { reducer as bulletinReducer } from "../slices/bulletin/bulletinSlice";
import { reducer as calendarReducer } from "../slices/calendar/calendarSlice";
interface RootState {
  classes: ReturnType<typeof classesReducer>;
  dashboard: ReturnType<typeof dashboardReducer>;
  session: ReturnType<typeof sessionReducer>;
  subject: ReturnType<typeof SubjectReducer>;
  correction: ReturnType<typeof correctionReducer>;
  bulletin: ReturnType<typeof bulletinReducer>;
  calendar: ReturnType<typeof calendarReducer>;
}
const reducers: ReducersMapObject<RootState> = {
  classes: classesReducer,
  dashboard: dashboardReducer,
  session: sessionReducer,
  subject: SubjectReducer,
  correction: correctionReducer,
  bulletin: bulletinReducer,
  calendar: calendarReducer,
};
const combinedReducer: Reducer<RootState> = combineReducers(reducers);

export default combinedReducer;
