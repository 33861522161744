function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "/";

export const pages = {
  login: "login",
  forgot_password: "forgot_password",
  sign_up: "sign_up",
  one: "dashboard",
  results: "results",
  verification: "verification",
  update_password: "update_password",
  user: "user",
  accept_invitation: "accept-invitation",
  correction: "correction",
  bulletin: "bulletin",
  profile: "profile",
  calendar: "calendar",
};
export const nestedPages = {
  results: {
    correction: path(
      `${path(ROOTS_DASHBOARD, pages.results)}/`,
      pages.correction
    ),
    bulletin: path(`${path(ROOTS_DASHBOARD, pages.results)}/`, pages.bulletin),
  },
};
export const PATH_AUTH = {
  login: path(ROOTS_DASHBOARD, pages.login),
  forgot_password: path(ROOTS_DASHBOARD, pages.forgot_password),
  sign_up: path(ROOTS_DASHBOARD, pages.sign_up),
  verification: path(ROOTS_DASHBOARD, pages.verification),
  update_password: path(ROOTS_DASHBOARD, pages.update_password),
  accept_invitation:
    path(ROOTS_DASHBOARD, pages.accept_invitation) +
    "/:resetToken/:studentId/:teacherId/:classId",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  one: path(ROOTS_DASHBOARD, pages.one),
  results: {
    root: path(ROOTS_DASHBOARD, pages.results),
    correction:
      nestedPages.results.correction +
      "/:examId/:subject/:sectionId/:sessionId",
    bulletin: nestedPages.results.bulletin + "/:subject/:sectionId",
  },

  user: {
    root: path(ROOTS_DASHBOARD, pages.user),
  },
  calendar: path(ROOTS_DASHBOARD, pages.calendar),
};

export const PATH_PROFILE = {
  root: ROOTS_DASHBOARD,
  profile: path(ROOTS_DASHBOARD, pages.profile),
};
