import { IconButton, InputAdornment } from "@mui/material";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import { useLocales } from "src/locales";
import { ReactComponent as Password } from "src/assets/icons/key.svg";
import Iconify from "src/components/iconify";
import { useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "src/components/button/Button";
import close from "src/assets/icons/close.svg";
import { useAuthContext } from "src/auth/useAuthContext";
import { message } from "antd";

export interface UpdatePassword {
  oldPassword: string;
  password: string;
  confirmPassword: string;
  afterSubmit?: string;
}
export default function PasswordForm({
  setClose,
}: {
  setClose: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { translate } = useLocales();
  const { updatePassword } = useAuthContext();
  const [showPassword, setShowPassword] = useState(false);
  const [showVerifPassword, setShowVerifPassword] = useState(false);
  const UpdatePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required(translate("auth.password_required"))
      .min(8, translate("auth.passwordLength")),
    password: Yup.string()
      .required(translate("auth.newPassword"))
      .min(8, translate("auth.passwordLength")),
    confirmPassword: Yup.string()
      .required(translate("auth.confirmNewPassword"))
      .oneOf(
        [Yup.ref("password"), null],
        translate("auth.confirmPasswordMismatch")
      ),
  });

  const defaultValues = {
    oldPassword: "",
    password: "",
    confirmPassword: "",
  };

  const methods = useForm<UpdatePassword>({
    resolver: yupResolver(UpdatePasswordSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data: UpdatePassword) => {
    const { oldPassword, password, confirmPassword } = data;
    try {
      const response = await updatePassword({
        oldPassword,
        password,
        confirmPassword,
      });
      if (response.status === 200) {
        message.success(response?.data?.message);
        setClose(false);
      }
    } catch (error) {
      message.error(error.response?.data.message || "something went  wrong");
      setError("afterSubmit", {
        ...error,
        message: error.response?.data.message || error,
      });
    }
  };
  return (
    <>
      <div className='password-overlay' onClick={() => setClose(false)}></div>
      <div className='password-modal'>
        <div className='password-modal_header'>
          <p>{`${translate("userInfo.modify")}`}</p>
          <img src={close} alt='error' onClick={() => setClose(false)} />
        </div>
        <FormProvider className='password-modal_form' methods={methods}>
          <RHFTextField
            required
            name='oldPassword'
            label={`${translate("auth.oldPassword")}`}
            type={showPassword ? "text" : "password"}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Password />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    className='show-password-icon'
                    onClick={() => setShowPassword(!showPassword)}
                    edge='end'
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <RHFTextField
            required
            name='password'
            label={`${translate("auth.newPassword")}`}
            type={showPassword ? "text" : "password"}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Password />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    className='show-password-icon'
                    onClick={() => setShowPassword(!showPassword)}
                    edge='end'
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <RHFTextField
            required
            name='confirmPassword'
            label={`${translate("auth.confirmNewPassword")}`}
            type={showVerifPassword ? "text" : "password"}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Password />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    className='show-password-icon'
                    onClick={() => setShowVerifPassword(!showVerifPassword)}
                    edge='end'
                  >
                    <Iconify
                      icon={
                        showVerifPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                      }
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormProvider>
        <div className='password-modal_btns'>
          <Button
            backgroundColor={0}
            color={2}
            borderColor={2}
            border={"1px solid"}
            hover={"-noHover"}
            onClick={() => setClose(false)}
          >
            {`${translate("userInfo.annuler")}`}
          </Button>
          <Button
            loading={isSubmitSuccessful || isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {`${translate("userInfo.submit")}`}
          </Button>
        </div>
      </div>
    </>
  );
}
