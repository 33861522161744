import { createSlice } from "@reduxjs/toolkit";
import { getCorrection } from "./correctionThunk";

export interface CorrectionSliceType {
  data: any[];
  loading: boolean;
  error?: boolean | null;
}

export const initialState: CorrectionSliceType = {
  data: [],
  loading: false,
  error: null,
};
export const CorrectionSlice: any = createSlice({
  name: "correction",
  initialState,
  reducers: {
    emptyExam(state, action) {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCorrection.pending, (state, action) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getCorrection.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.data = action?.payload?.data?.StudentExamResult;
      })
      .addCase(getCorrection.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      });
  },
});

export const { emptyExam } = CorrectionSlice.actions;

export const reducer = CorrectionSlice.reducer;
