import { useState } from "react";
import { useLocales } from "src/locales";
import Lock from "../../../../assets/icons/password.svg";
import PasswordForm from "./PasswordForm";

export default function Password() {
  const { translate } = useLocales();
  const [close, setClose] = useState<boolean>(false);

  return (
    <div className="updated-password">
        <p className="updated-password_title">{`${translate(
          "userInfo.security"
        )}`}</p>
        <img src={Lock} alt="error" className="updated-password_img" />
        <button
          className="updated-password_button"
          onClick={() => {
            setClose(true);
          }}
        >{`${translate("userInfo.modify")}`}</button>
        {close && <PasswordForm setClose={setClose} />}
    </div>
  );
}
