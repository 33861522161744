
export default function ProfileElement({
  field,
  value,
}: {
  field: string;
  value: string;
}) {
  return (
    <div className="update-picture_box">
      <p className="update-picture_title">{field}</p>
      <p className="update-picture_value">{value}</p>
    </div>
  );
}
