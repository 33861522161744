import React, { useState } from "react";
import ImgCrop from "antd-img-crop";
import PictureIcon from "src/assets/icons/image.svg";
import { Upload, message } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import plus from "../../../../assets/icons/plusCircle.svg";
import { useAuthContext } from "src/auth/useAuthContext";
import ProfileElement from "./ProfileElement";

const Image: React.FC = () => {
  const { updateUser, user } = useAuthContext();
  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: user?.photo,
    },
  ]);
  const [progress, setProgress] = useState(0);

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    //@ts-ignore
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const uploadImage = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    fmData.append("photo", file);

    await updateUser({ fmData, onSuccess, onError, onProgress, setProgress });
  };
  const uploadButton = (
    <div>
      <img src={PictureIcon} alt="" style={{ width: "40px" }} />
    </div>
  );

  return (
    <article className="update-picture_container">
      <div className="update-picture">
        <div className="update-picture_image">
          <ImgCrop rotationSlider>
            <Upload
              showUploadList={{ showPreviewIcon: false, showRemoveIcon: true }}
              customRequest={uploadImage}
              listType="picture-circle"
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
              beforeUpload={beforeUpload}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
          </ImgCrop>
          <img src={plus} alt="error" className="plusCircle" />
        </div>
        <div className="update-picture-det">
          <div className="update-picture-det-title">Modifier votre photo</div>
          <div className="update-picture-det-description">
            Taille maximale est de 5 Mo
          </div>
        </div>
      </div>
      <p className="update-picture_text">Informations personnelles</p>
      <ProfileElement field="Nom" value={user?.firstName} />
      <ProfileElement field="Prénom" value={user?.lastName} />
      <ProfileElement field="E-mail" value={user?.email} />
      {user?.government && (
        <ProfileElement field="Gouvernorat" value={user?.government} />
      )}
      {user?.establishment?.map((el: any) => {
        return <ProfileElement field="Établissement" value={el?.name} />;
      })}
    </article>
  );
};

export default Image;
