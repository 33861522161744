import CustomHelmet from "src/components/customHelmet/CustomHelmet";
import Login from "../sections/auth/Login";

export default function LoginPage() {

  return (
    <>
      <CustomHelmet
        title="login | Ixamee"
        description="login to ixamee"
        name="ixamee"
        type="auth"
        imageURL="/assets/illustrations/Illustration-login.svg"
      />

      <Login />
    </>
  );
}
