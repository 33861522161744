import Image from "./components/Image/Image";
import Password from "./components/Password/Password";

export default function Profile() {
  return (
    <section className="profile">
      <Image />
      <Password />
    </section>
  );
}
