import { Suspense, lazy, ElementType } from "react";
import { ReactComponent as Loader } from "src/assets/logo/ixamee.svg";

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense
      fallback={
        <div className='auth_loader'>
          <Loader />
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );

export const LoginPage = Loadable(lazy(() => import("../pages/LoginPage")));
export const profile = Loadable(lazy(() => import("../pages/Profile")));
export const PageOne = Loadable(lazy(() => import("../pages/PageOne")));
export const Results = Loadable(lazy(() => import("../pages/ResultsPage")));
export const SignUp = Loadable(lazy(() => import("../pages/SignUpPage")));
export const UpdatePassword = Loadable(
  lazy(() => import("../pages/UpdatePasswordPage"))
);
export const Forgotpassword = Loadable(
  lazy(() => import("../pages/ForgotPasswordPage"))
);
export const Verification = Loadable(
  lazy(() => import("../pages/VerificationPage"))
);
export const Invitation = Loadable(
  lazy(() => import("../pages/InvitationPage"))
);
export const Correction = Loadable(
  lazy(() => import("../pages/CorrectionPage"))
);
export const Bulletin = Loadable(lazy(() => import("../pages/BulletinPage")));
export const Calendar = Loadable(lazy(() => import("../pages/Calendar")));

export const Page404 = Loadable(lazy(() => import("../pages/Page404")));
