// @mui
import { styled } from "@mui/material/styles";
import { ListItemIcon, ListSubheader, ListItemButton } from "@mui/material";
// config
import { ICON, NAV } from "../../../config-global";
//
import { NavItemProps } from "../types";

// ----------------------------------------------------------------------

type StyledItemProps = Omit<NavItemProps, "item"> & {
  caption?: boolean;
  disabled?: boolean;
};

export const StyledItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "caption",
})<StyledItemProps>(({ active, disabled, depth, caption, theme }) => {
  const activeStyle = {
    color: "#1B8392",
    backgroundColor: "#F3F6F6",
  };

  return {
    position: "relative",
    textTransform: "capitalize",
    paddingRight: theme.spacing(2),
    color: "#F3F6F6",
    backgroundColor: "#1B8392",
    borderRadius: 10,
    paddingLeft: 10,
    height: NAV.H_DASHBOARD_ITEM,
    "&:hover": {
      opacity: 0.7,
    },
    // Active item
    ...(active && {
      ...activeStyle,
      "&:hover": {
        ...activeStyle,
        opacity: 0.7,
      },
    }),

    // Disabled
    ...(disabled && {
      "&.Mui-disabled": {
        opacity: 0.64,
      },
    }),
  };
});

// ----------------------------------------------------------------------
type StyledDotIconProps = {
  active?: boolean;
};
export const StyledIcon = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== "active",
})<StyledDotIconProps>(({ active, theme }) => ({
  display: "flex",
  alignItems: "start",
  justifyContent: "center",
  filter:
    "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(271%) hue-rotate(46deg) brightness(117%) contrast(100%)",
  width: ICON.NAV_ITEM,

  height: ICON.NAV_ITEM,
  ...(active && {
    filter:
      "brightness(0) saturate(100%) invert(41%) sepia(87%) saturate(389%) hue-rotate(139deg) brightness(87%) contrast(94%)",
  }),
}));
export const StyledItemIcon = styled("div", {
  shouldForwardProp: (prop) => prop !== "active",
})<StyledDotIconProps>(({ active, theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#1B8392",
  width: "50px",
  borderRadius: "6px",
  padding: "5px 0px",
  paddingLeft: 15,

  ...(active && {
    backgroundColor: "#F3F6F6",
  }),
}));
// ----------------------------------------------------------------------

export const StyledDotIcon = styled("span", {
  shouldForwardProp: (prop) => prop !== "active",
})<StyledDotIconProps>(({ active, theme }) => ({
  width: 4,
  height: 4,
  borderRadius: "50%",
  backgroundColor: theme.palette.text.disabled,
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shorter,
  }),

  ...(active && {
    transform: "scale(2)",
    backgroundColor: theme.palette.primary.main,
    filter:
      "brightness(0) saturate(100%) invert(39%) sepia(98%) saturate(395%) hue-rotate(140deg) brightness(89%) contrast(86%)",
  }),
}));

// ----------------------------------------------------------------------

export const StyledSubheader = styled(ListSubheader)(({ theme }) => ({
  ...theme.typography.overline,
  fontSize: 11,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.secondary,
}));
