import "./locales/i18n";
import "simplebar-react/dist/simplebar.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Router from "./routes";
import ThemeProvider from "./theme";
import ThemeLocalization from "./locales";
import SnackbarProvider from "./components/snackbar";
import ScrollToTop from "./components/scroll-to-top";
import { MotionLazyContainer } from "./components/animate";

import { AuthProvider } from "./auth/JwtContext";
import { setAccessSession, setRefreshSession } from "./auth/utils";

export default function App() {
  const beforeUnloadHandler = (e: any) => {
    e.preventDefault();
    let stayConnected: any = localStorage.getItem("stayConnected");
    stayConnected = JSON.parse(stayConnected);
    console.log(stayConnected);
    if (!stayConnected) {
      setRefreshSession(null);
      setAccessSession(null);
      return false;
    }
    return true;
  };
  window.addEventListener("unload", function (e) {
    beforeUnloadHandler(e);
  });

  return (
    <AuthProvider>
      <HelmetProvider>
        <BrowserRouter>
          <ScrollToTop />
          <MotionLazyContainer>
            <ThemeProvider>
              <ThemeLocalization>
                <SnackbarProvider>
                  <Router />
                </SnackbarProvider>
              </ThemeLocalization>
            </ThemeProvider>
          </MotionLazyContainer>
        </BrowserRouter>
      </HelmetProvider>
    </AuthProvider>
  );
}
