import { useFormContext, Controller } from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";
type Props = TextFieldProps & {
  name: string;
  methods?: any;
};

export default function RHFTextField({
  name,
  helperText,
  label,
  required,
  methods,
  ...other
}: Props) {
  const { control } = useFormContext();
  const handleFieldBlur = (fieldName: string) => {
    methods?.trigger(fieldName);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }: any) => {
        return (
          <div className="input">
            <h3 className="input-label">
              {label}
              {required && <span className="red-star">*</span>}
            </h3>
            <TextField
              onBlurCapture={() => handleFieldBlur(name)}
              {...field}
              fullWidth
              required={required}
              value={
                typeof field.value === "number" && field.value === 0
                  ? ""
                  : field.value
              }
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...other}
            />
          </div>
        );
      }}
    />
  );
}
