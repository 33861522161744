import { List, Stack } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import bag from '../../../assets/icons/bag.svg';
import { NavSectionProps } from "../types";
import NavList from "./NavList";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { Class, setActiveClass } from "src/data/slices/classes/ClassesSlice";
import { RHFRadioGroup } from "src/components/hook-form";
import { useLocales } from "src/locales";
import useResponsive from "src/hooks/useResponsive";

export default function NavSectionVertical({
  data,
  sx,
  openNav,
  isDesktop,
  ...other
}: NavSectionProps) {
  const methods = useForm();
  const dispatch = useAppDispatch();
  const { translate } = useLocales();
  const { classes, activeClass } = useAppSelector((state) => state?.classes);
  const items = classes?.map((establishement: Class) => ({
    label: establishement?.name,
    value: establishement?._id,
  }));
  const handleChange = (event: any) => {
    const selected = items.filter(
      (item: { label: string; value: string }) =>
        item?.value === event.target.value
    );
    dispatch(
      setActiveClass({ _id: selected[0].value, name: selected[0].label })
    );
    localStorage.setItem(
      "class",
      JSON.stringify({ _id: selected[0].value, name: selected[0].label })
    );
  };
  const isDesk = useResponsive("up", "md");
  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => {
        const key = group.items[0].title;

        return (
          <div key={key} className='sidebar_container'>
            <List
              sx={{
                px: 1.5,
                gap: "10px",
                display: "flex",
                flexDirection: "column",
                alignItem: "start",
                justifyContent: "start",
              }}
            >
              {group?.items?.map((list) => (
                <NavList
                  openNav={openNav}
                  isDesktop={isDesktop}
                  key={list.title + list.path}
                  data={list}
                  depth={1}
                />
              ))}
            </List>
            
            <div className='radio'>
              <div className={openNav ? "divider" : "divider-small"}></div>
              <div className={'navSection'}>
                <img className="navSection_text" src={bag} alt="bag" />
                {openNav && <p className="navSection_text">{`${translate("sidebar.classes")}`}</p>}
                </div>
              <FormProvider {...methods}>
                <RHFRadioGroup
                  spacing={0.1}
                  handleChange={handleChange}
                  defaultValue={activeClass?._id}
                  name='classes'
                  options={items}
                  openNav={openNav}
                />
              </FormProvider>
            </div>
          </div>
        );
      })}
    </Stack>
  );
}
