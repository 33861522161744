import { createSlice } from "@reduxjs/toolkit";
import {
  getCorrectionInfo,
  getDashboardInfo,
  getDashboardMarkSheet,
  getStudentStatistics,
} from "./DashboardThunk";

interface TeacherSubject {
  _id: string;
  name: string;
  photo: string;
}

interface Teacher {
  _id: string;
  firstName: string;
  lastName: string;
  subjects: TeacherSubject[];
}

interface Section {
  _id: string;
  teacherId: Teacher;
}

interface Exam {
  _id: string;
  name: string;
  score: number;
}

export interface ExamResult {
  _id: string;
  examId: Exam;
  sectionId: Section;
  score: number;
  range: number;
  updatedAt: string;
}

export interface StatElement {
  above85: number;
  between50And75: number;
  between75And85: number;
  lessThan50: number;
}
interface DashboardInterface {
  data: {
    total_subject: number | null;
    total_newsletter: number | null;
    totalExam: number | null;
  };
  exams: ExamResult[] | {};
  markSheet: any;
  CorrectionExamStatistic: StatElement[] | [];
  loading: boolean;
  Examloading: boolean;
  markSheetLoading: boolean;
  statsLoading: boolean;
  error: string | null | unknown;
  CorrectionError: string | null | unknown;
}

export const initialState: DashboardInterface = {
  data: {
    total_subject: null,
    total_newsletter: null,
    totalExam: null,
  },
  exams: {},
  markSheet: {},
  CorrectionExamStatistic: [],
  loading: false,
  Examloading: false,
  markSheetLoading: false,
  statsLoading: false,
  error: null,
  CorrectionError: null,
};

export const dashboardSlice: any = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardInfo.pending, (state, { payload }) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getDashboardInfo.fulfilled, (state, { payload }) => {
        state.error = null;
        state.loading = false;
        state.data = payload?.data?.subjectsNewslettersExams;
      })
      .addCase(getDashboardInfo.rejected, (state, { payload }) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getCorrectionInfo.pending, (state, { payload }) => {
        state.error = null;
        state.Examloading = true;
      })
      .addCase(getCorrectionInfo.fulfilled, (state, { payload }) => {
        state.error = null;
        state.Examloading = false;
        state.exams = payload?.data?.payload;
      })
      .addCase(getCorrectionInfo.rejected, (state, { payload }) => {
        state.error = true;
        state.Examloading = false;
      })
      .addCase(getDashboardMarkSheet.pending, (state, { payload }) => {
        state.CorrectionError = null;
        state.markSheetLoading = true;
      })
      .addCase(getDashboardMarkSheet.fulfilled, (state, { payload }) => {
        state.CorrectionError = null;
        state.markSheetLoading = false;
        state.markSheet = payload?.data?.payload;
      })
      .addCase(getDashboardMarkSheet.rejected, (state, { payload }) => {
        state.CorrectionError = true;
        state.markSheetLoading = false;
      })
      .addCase(getStudentStatistics.pending, (state, { payload }) => {
        state.error = null;
        state.statsLoading = true;
      })
      .addCase(getStudentStatistics.fulfilled, (state, { payload }) => {
        state.error = null;
        state.statsLoading = false;
        state.CorrectionExamStatistic = payload?.data?.CorrectionExamStatistic;
      })
      .addCase(getStudentStatistics.rejected, (state, { payload }) => {
        state.error = true;
        state.statsLoading = false;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = dashboardSlice.actions;

export const reducer = dashboardSlice.reducer;

export default dashboardSlice;
