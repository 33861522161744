import { setRefreshSession } from "./../auth/utils";
import axios, { AxiosResponse } from "axios";
import { isValidToken, setAccessSession } from "src/auth/utils";
// config
import { HOST_API_KEY } from "../config-global";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: any) => {
    const previousRequest: any = error?.config;
    if (
      (error.statusCode === 400 &&
        error.response?.data.message === "authorization is required") ||
      error.statusCode === 401
    ) {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken && isValidToken(refreshToken)) {
        if (!accessToken || !isValidToken(accessToken)) {
          const response: any = await axiosInstance.post(
            "/auth/teacher/refresh-token",
            {
              refreshToken,
            }
          );
          const access = response.data.data.accessToken;
          setAccessSession(access);

          return axiosInstance(previousRequest);
        }
      } else {
        setAccessSession(null);
        setRefreshSession(null);
      }
    }
    // if (error?.response?.status === 500) {
    //   window.location.replace("/network");
    //   return Promise.reject("");
    // }
    return Promise.reject(error);
  }
);
export default axiosInstance;
