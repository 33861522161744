import { PATH_AUTH } from "../routes/paths";

import axios from "../utils/axios";

function jwtDecode(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken?: any) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------
const getNewAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (isValidToken(refreshToken)) {
    try {
      const response = await axios.post("/auth/teacher/refresh-token", {
        refreshToken,
      });
      const { accessToken } = response.data.data;

      setAccessSession(accessToken);
    } catch (err) {
      setRefreshSession(null);

      window.location.href = PATH_AUTH.login;
    }
  } else {
    setRefreshSession(null);
    setAccessSession(null);
    window.location.href = PATH_AUTH.login;
  }
};
export const tokenExpired = (exp: number, type: string) => {
  let expiredTimer;

  const currentTime = Date.now();

  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    if (type === "refreshToken") {
      setRefreshSession(null);
      setAccessSession(null);
      window.location.href = "/login";
    } else {
      getNewAccessToken();
    }
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setAccessSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);

    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    const { exp } = jwtDecode(accessToken);

    tokenExpired(exp, "accessToken");
  } else {
    localStorage.removeItem("accessToken");

    delete axios.defaults.headers.common["Authorization"];
  }
};
export const setRefreshSession = (refreshToken: string | null) => {
  if (refreshToken) {
    localStorage.setItem("refreshToken", refreshToken);

    const { exp } = jwtDecode(refreshToken);

    tokenExpired(exp, "refreshToken");
  } else {
    localStorage.clear();
  }
};
