import { useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Alert, IconButton, InputAdornment } from "@mui/material";
import { ReactComponent as Mail } from "src/assets/icons/mail.svg";
import { ReactComponent as Password } from "src/assets/icons/key.svg";

import { useAuthContext } from "../../auth/useAuthContext";
import Iconify from "../../components/iconify";
import FormProvider, { RHFTextField } from "../../components/hook-form";
import { useLocales } from "src/locales";
import { Link, useNavigate } from "react-router-dom";
import { PATH_AUTH } from "src/routes/paths";
import Button from "src/components/button/Button";
import AuthCheckbox from "src/components/AuthCheckbox";
import { TRUE } from "sass";
type FormValuesProps = {
  email: string;
  password: string;
  stayConnected: boolean;
  afterSubmit?: string;
};

export default function AuthLoginForm() {
  const { login } = useAuthContext();
  const { translate } = useLocales();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required(translate("auth.mail_required"))
      .email(translate("auth.mail_valid")),
    password: Yup.string().required(translate("auth.password_required")),
  });

  const defaultValues = {
    email: "",
    password: "",
    stayConnected: true,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      localStorage.setItem("stayConnected", JSON.stringify(data.stayConnected));
      const response = await login(data.email, data.password);
      navigate(PATH_AUTH.verification, { state: response });
    } catch (error) {
      console.error(error);

      reset();

      setError("afterSubmit", {
        ...error,
        message: error.response?.data.message || error,
      });
    }
  };

  return (
    <FormProvider
      className="login-form"
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        <RHFTextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Mail />
              </InputAdornment>
            ),
          }}
          name="email"
          label={`${translate("auth.mail")}`}
        />

        <RHFTextField
          name="password"
          label={`${translate("auth.password")}`}
          type={showPassword ? "text" : "password"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Password />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  className="show-password-icon"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <AuthCheckbox
          id="stay-connected-login"
          onChange={(value: any) => {
            methods.setValue("stayConnected", value.target.checked);
          }}
          defaultValues={true}
          label="Rester connecté"
          labelStyle="stay-connected-login"
        />
        <Link to={PATH_AUTH.forgot_password} className="forgot-password">
          {`${translate("auth.forgot_password")}`}
        </Link>
      </Stack>

      <Button loading={isSubmitSuccessful || isSubmitting}>
        {`${translate("auth.sign_in")}`}
      </Button>
    </FormProvider>
  );
}
