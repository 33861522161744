// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import { ReactComponent as Home } from "src/assets/icons/home.svg";
import { ReactComponent as Classes } from "src/assets/icons/classes.svg";
import { ReactComponent as Calendar } from "src/assets/icons/calendar.svg";

import { ReactComponent as Establishements } from "src/assets/icons/establishement.svg";

const ICONS = {
  home: <Home />,
  classes: <Classes />,
  calendar: <Calendar />,
  establishement: <Establishements />,
};

const navConfig = [
  {
    items: [
      { title: "dashboard", path: PATH_DASHBOARD.one, icon: ICONS.home },
      {
        title: "results",
        path: PATH_DASHBOARD.results.root,
        icon: ICONS.classes,
      },
      {
        title: "calendar",
        path: PATH_DASHBOARD.calendar,
        icon: ICONS.calendar,
      },
    ],
  },
];

export default navConfig;
