import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { ReactComponent as Loader } from "src/assets/logo/ixamee.svg";
//
import { useAuthContext } from "./useAuthContext";
import { PATH_DASHBOARD } from "src/routes/paths";

type GuestGuardProps = {
  type?: string;
  children: ReactNode;
};

export default function GuestGuard({ type, children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized, logout } = useAuthContext();
  if (type === "invitation" && isAuthenticated) {
    const logoutFunc = async () => {
      await logout();
    };

    logoutFunc();
  }
  if (!(type === "invitation")) {
    if (isAuthenticated) {
      return <Navigate to={PATH_DASHBOARD.one} />;
    }
    if (!isInitialized) {
      return (
        <div className="auth_loader">
          <Loader />
        </div>
      );
    }
  }

  return <> {children} </>;
}
