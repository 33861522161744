import { createSlice } from "@reduxjs/toolkit";
import { getBulletin } from "./bulletinThunk";

export interface BulletinSliceType {
  data: any[];
  average: number | null;
  range: number | null;
  teacherName?:string;
  establishment?:string;
  loading: boolean;
  error?: boolean | null;
}

export const initialState: BulletinSliceType = {
  data: [],
  average: null,
  range: null,
  teacherName:undefined,
  establishment:undefined,

  loading: false,
  error: null,
};
export const BulletinSlice: any = createSlice({
  name: "bulletin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBulletin.pending, (state, action) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getBulletin.fulfilled, (state, action) => {
console.log(action);

        state.error = null;
        state.loading = false;
        state.data = action?.payload?.data?.results;
        state.average = action?.payload?.data?.average;
        state.range = action?.payload?.data?.range;
state.teacherName = action?.payload?.data?.teacherName;
state.establishment = action?.payload?.data?.establishment?.name;

      })
      .addCase(getBulletin.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = BulletinSlice.actions;

export const reducer = BulletinSlice.reducer;
