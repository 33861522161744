import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import useActiveLink from "../../../hooks/useActiveLink";
import { NavListProps } from "../types";
import NavItem from "./NavItem";

type NavListRootProps = {
  openNav?: boolean;
  data: NavListProps;
  isDesktop?: boolean;
  depth: number;
};

export default function NavList({
  isDesktop,
  openNav,
  data,
  depth,
}: NavListRootProps) {
  const { pathname } = useLocation();

  const { active, isExternalLink } = useActiveLink(data.path);

  const [open, setOpen] = useState(active);

  useEffect(() => {
    if (!active) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <NavItem
        openNav={openNav}
        item={data}
        depth={depth}
        isDesktop={isDesktop}
        open={open}
        active={active}
        isExternalLink={isExternalLink}
        onClick={handleToggle}
      />
    </>
  );
}
