import useResponsive from "../../../hooks/useResponsive";
import { NavSectionVertical } from "../../../components/nav-section";
import navConfig from "./config-navigation";
type Props = {
  openNav: boolean;
};

export default function NavVertical({ openNav }: Props) {
  const isDesktop = useResponsive("up", "md");

  const renderContent = (
    <NavSectionVertical
      isDesktop={isDesktop}
      openNav={openNav}
      data={navConfig}
    />
  );

  return (
    <aside
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={openNav ? "sidebar" : "sidebar sidebar-small"}
    >
      {renderContent}
    </aside>
  );
}
