import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";
interface getBulletinProps {
  classId: string;
  sectionId: string;
}

export const getBulletin = createAsyncThunk(
  "bulletin/getBulletin",
  async ({ classId, sectionId }: getBulletinProps) => {
    let data;
    try {
      const response = await axiosInstance.get(
        `/students/class/${classId}/section/${sectionId}`
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
