import { Helmet } from "react-helmet-async";

interface SEOTypes {
  title: string;
  description: string;
  name: string;
  type: string;
  imageURL?: string;
}
export default function CustomHelmet({
  title,
  description,
  name,
  type,
  imageURL,
}: SEOTypes) {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* End standard metadata tags */}
      {/* Facebook  and liknedin and instagram tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageURL} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageURL} />
      {/* End Twitter tags */}
      {/* Pinterest tags */}
      <meta name="pinterest-rich-pin" content="true" />
      <meta name="description" content={description} />
      <meta property="og:image" content={imageURL} />
      {/* End Pinterest tags */}
      {/* WhatsApp tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/*  End WhatsApp tags */}
    </Helmet>
  );
}
