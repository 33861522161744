import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";
interface getCorrectionProps {
  classId: string;
  sectionId: string;
  sessionId: string;
  examId: string;
}

export const getCorrection = createAsyncThunk(
  "session/getCorrection",
  async ({ examId, classId, sectionId, sessionId }: getCorrectionProps) => {
    let data;

    try {
      const response = await axiosInstance.get(
        `students/exam-result/exam/${examId}/class/${classId}/section/${sectionId}/session/${sessionId}`
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
