import { IconButton, useMediaQuery } from "@mui/material";
import Logo from "../../../components/logo";
import AccountPopover from "./AccountPopover";
import { ReactComponent as Burger } from "src/assets/icons/burger.svg";
type Props = {
  onOpenNav?: VoidFunction;
  openNav?: boolean;
};

export default function Header({ openNav, onOpenNav }: Props) {
  const isDesktop = useMediaQuery("(min-width:500px)");

  const renderContent = (
    <>
      <div className="header_first">
        {isDesktop && openNav && <Logo small />}
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: "text.primary" }}>
          <Burger />
        </IconButton>
      </div>
      <div className="header_end">
        {/* <LanguagePopover />*/}
        {/* <NotificationsPopover /> */}
        <AccountPopover />
      </div>
    </>
  );

  return <nav className="header">{renderContent}</nav>;
}
