import { login_breakpoint } from "src/assets/styles/abstracts/breakpoints";
import { useMediaQuery } from "@mui/material";

type Props = {
  Illustration?: any;
  direction?: "rtl" | "ltr";
  children: React.ReactNode;
};

export default function LoginLayout({
  direction = "ltr",
  children,
  Illustration,
}: Props) {
  const breakPoint = useMediaQuery(`(min-width:${login_breakpoint})`);
  return (
    <div className={`login-layout login-layout-${direction}`}>
      <div
        className={`login-layout_children login-layout_children-${direction}`}
      >
        {children}
      </div>

      {breakPoint && (
        <div className="login-layout_illustration">{Illustration}</div>
      )}
    </div>
  );
}
