import { Navigate, useRoutes } from "react-router-dom";
import ChangeClassHandler from "src/components/changeClassHandler/ChangeClassHandler";
import Profile from "src/pages/Profile";
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";

import {
  Page404,
  PageOne,
  Results,
  UpdatePassword,
  Forgotpassword,
  LoginPage,
  SignUp,
  Invitation,
  Verification,
  Correction,
  Bulletin,
  Calendar,
} from "./elements";
import { PATH_AUTH, PATH_DASHBOARD, PATH_PROFILE } from "./paths";

export default function Router() {
  return useRoutes([
    {
      children: [
        {
          path: PATH_AUTH.login,
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: PATH_AUTH.sign_up,
          element: (
            <GuestGuard>
              <SignUp />
            </GuestGuard>
          ),
        },
        {
          path: PATH_AUTH.verification,
          element: (
            <GuestGuard>
              <Verification />
            </GuestGuard>
          ),
        },
        {
          path: PATH_AUTH.forgot_password,
          element: (
            <GuestGuard>
              <Forgotpassword />
            </GuestGuard>
          ),
        },
        {
          path: PATH_AUTH.accept_invitation,
          element: (
            <GuestGuard type="invitation">
              <Invitation />
            </GuestGuard>
          ),
        },
        {
          path: PATH_AUTH.update_password,
          element: (
            <GuestGuard>
              <UpdatePassword />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: PATH_DASHBOARD.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_DASHBOARD.one} />, index: true },
        {
          path: PATH_DASHBOARD.one,
          element: (
            <AuthGuard>
              <PageOne />
            </AuthGuard>
          ),
        },

        {
          path: PATH_DASHBOARD.results.root,
          element: (
            <AuthGuard>
              <Results />
            </AuthGuard>
          ),
        },
        {
          path: PATH_DASHBOARD.results.correction,
          element: (
            <AuthGuard>
              <ChangeClassHandler>
                <Correction />
              </ChangeClassHandler>
            </AuthGuard>
          ),
        },
        {
          path: PATH_DASHBOARD.results.bulletin,
          element: (
            <AuthGuard>
              <ChangeClassHandler>
                <Bulletin />
              </ChangeClassHandler>
            </AuthGuard>
          ),
        },
        {
          path: PATH_PROFILE.profile,
          element: (
            <AuthGuard>
              <Profile />
            </AuthGuard>
          ),
        },
        {
          path: PATH_DASHBOARD.calendar,
          element: (
            <AuthGuard>
              <Calendar />
            </AuthGuard>
          ),
        },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/four" replace />,
              index: true,
            },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: "404", element: <Page404 /> }],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
