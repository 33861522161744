import { Stack } from "@mui/material";
import LoginLayout from "../../layouts/login";
import { ReactComponent as Illustration } from "../../assets/illustrations/illustration-login.svg";
import AuthLoginForm from "./AuthLoginForm";
import { useLocales } from "src/locales";
import header from '../../assets/icons/header-icon.svg'
import { Link } from "react-router-dom";
import { PATH_AUTH } from "src/routes/paths";
export default function Login() {
  const { translate } = useLocales();
  return (
    <LoginLayout Illustration={<Illustration />}>

      <div className="login-form login-form-container">
        <img src={header} alt="error" />
        <h4 className="login-form_title">{`${translate("auth.login")}`}</h4>
        <AuthLoginForm />
      </div>

      <Stack direction="row" spacing={0.5}>
        <p className="no-account">{`${translate("auth.no_account")}`}</p>

        <Link to={PATH_AUTH.sign_up}>{`${translate("auth.sign_up")}`}</Link>
      </Stack>
    </LoginLayout>
  );
}
