import { useState } from "react";
import { Outlet } from "react-router-dom";
import useResponsive from "../../hooks/useResponsive";
import Header from "./header";
import NavVertical from "./nav/NavVertical";

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const isDesktop = useResponsive("up", "md");

  const [open, setOpen] = useState(isDesktop);

  const handleOpen = () => {
    setOpen(!open);
  };

  const renderNavVertical = <NavVertical openNav={open} />;

  return (
    <section className='layout'>
      <Header openNav={open} onOpenNav={handleOpen} />
      <section className='layout_down'>
        {isDesktop
          ? renderNavVertical
          : open && (
              <button className='sidebar-responsive' onClick={handleOpen}>
                {renderNavVertical}
              </button>
            )}
        <main className='main'>
          <Outlet />
        </main>
      </section>
    </section>
  );
}
