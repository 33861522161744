import { useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// @mui
import ExitApp from "../../../assets/icons/log-out.png";
import Profile from "../../../assets/icons/user.png";
// routes
import { PATH_AUTH } from "../../../routes/paths";
// auth
import { useAuthContext } from "../../../auth/useAuthContext";
// components
import { CustomAvatar } from "../../../components/custom-avatar";
import { useSnackbar } from "../../../components/snackbar";
import MenuPopover from "../../../components/menu-popover";
import { IconButtonAnimate } from "../../../components/animate";
import { useLocales } from "src/locales";
import arrow from "../../../assets/icons/arrow.svg";
import User from "../../../assets/icons/user.svg";
import Log from "../../../assets/icons/log-out.png";
import { Dropdown, MenuProps } from "antd";
import Link from "src/theme/overrides/Link";

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: "Profile",
    linkTo: "/profile",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { user, logout } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover]: any = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };
  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  const handleClickItem = (path: string) => {
    handleClosePopover();
    navigate(path);
  };
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a className="profile-btn" href="/profile">
          <img src={Profile} alt="error" className="menu-logout" />
          Profil
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <button onClick={handleLogout} className="profile-btn">
          <img src={ExitApp} alt="error" className="menu-logout" />
          {`${translate("header.logout")}`}
        </button>
      ),
    },
  ];
  return (
    <>
      <Dropdown
        placement="bottomLeft"
        onOpenChange={() => {
          setOpenPopover(!openPopover);
        }}
        open={openPopover}
        menu={{ items }}
        trigger={["click"]}
      >
        <div className="user-header" onClick={handleOpenPopover}>
          <CustomAvatar
            src={user?.photo}
            alt={user?.firstName}
            name={user?.firstName}
          />
          <div>
            <p className="user-name">
              {user?.firstName + " "}
              {user?.lastName}
            </p>
            <p className="user-email">{user?.email}</p>
          </div>

          <img src={arrow} alt="error" className="user-arrow" />
        </div>
      </Dropdown>
    </>
  );
}
