import { forwardRef } from "react";
import { Icon } from "@iconify/react";
import { Box, BoxProps } from "@mui/material";
import { IconifyProps } from "./types";

interface Props extends Omit<BoxProps, "component"> {
  icon: IconifyProps |     string;
}

const Iconify = forwardRef<SVGSVGElement, Props>(
  ({ icon, width = 20, sx, ...other }, ref) => (
    <Box ref={ref} sx={{ width, height: width, ...sx }} {...other}>
      <Icon icon={icon} />
    </Box>
  )
);

export default Iconify;
