import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

export const getEvents = createAsyncThunk(
  "calendar/getCalendar",
  async ({
    classId,
    startDate,
    endDate,
  }: {
    classId: string;
    startDate: string;
    endDate: string;
  }) => {
    let data;
    try {
      const response = await axiosInstance.get(`calendar/student/${classId}`, {
        params: {
          startDate,
          endDate,
          perPage: 5000,
          page: 1,
        },
      });
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
