import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";

import { Link, BoxProps } from "@mui/material";
import { ReactComponent as MainLogo } from "src/assets/logo/main_logo.svg";
import { ReactComponent as SmallLogo } from "../../assets/logo/secondary_logo.svg"; // Import the small version of the logo

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  small?: boolean; // Add the small prop
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, small = false, sx, ...other }, ref) => {
    const logo = small ? <SmallLogo /> : <MainLogo />; // Use the small logo if small prop is true

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: "contents" }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
