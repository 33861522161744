import { Link as RouterLink } from "react-router-dom";
// @mui
import { Link, ListItemText } from "@mui/material";
// locales
import { useLocales } from "../../../locales";
// auth
import RoleBasedGuard from "../../../auth/RoleBasedGuard";
//
//
import { NavItemProps } from "../types";
import { StyledItem, StyledIcon, StyledItemIcon } from "./styles";

// ----------------------------------------------------------------------

export default function NavItem({
  item,
  depth,
  open,
  openNav,
  active,
  isDesktop,
  isExternalLink,
  ...other
}: NavItemProps) {
  const { translate } = useLocales();

  const { title, path, icon, disabled, caption, roles } = item;

  const renderContent = openNav ? (
    <StyledItem
      depth={depth}
      active={active}
      disabled={disabled}
      caption={!!caption}
      {...other}
      style={{
        transition: "all 0.3s ease-in-out",
      }}
    >
      {icon && <StyledIcon active={active}>{icon}</StyledIcon>}

      <ListItemText
        primary={`${translate(`sidebar.${title}`)}`}
        primaryTypographyProps={{
          noWrap: true,
          component: "span",
          variant: active ? "subtitle2" : "body2",
          style: {
            fontSize: "2rem",
            fontWeight: "normal",
            transition: "all 0.3s ease-in-out",
            fontFamily: "Poppins",
            textTransform: "none",
          },
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: "caption",
        }}
      />
    </StyledItem>
  ) : (
    <StyledItemIcon active={active}>
      <StyledIcon active={active}>{icon}</StyledIcon>
    </StyledItemIcon>
  );
  const renderItem = () => {
    // ExternalLink
    if (isExternalLink)
      return (
        <Link
          href={path}
          target='_blank'
          rel='noopener'
          underline='none'
          style={{
            transition: "all 0.3s ease-in-out",
          }}
        >
          {renderContent}
        </Link>
      );

    // Default
    return (
      <Link
        component={RouterLink}
        to={path}
        underline='none'
        style={{
          transition: "all 0.3s ease-in-out",
        }}
      >
        {renderContent}
      </Link>
    );
  };

  return <RoleBasedGuard roles={roles}> {renderItem()} </RoleBasedGuard>;
}
