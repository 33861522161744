import { CircularProgress } from "@mui/material";
import { ButtonTypes } from "./types";
import { Link } from "react-router-dom";

export default function Button({
  hover = "",
  backgroundColor = 2,
  color = 0,
  borderColor = 2,
  border = false,
  paddingTopBottom = 10,
  paddingLeftRight = 15,
  children,
  prefix,
  height,
  fontSize,
  suffix,
  className = "",
  width = "100%",
  borderRadius = 8,
  loading,
  onClick,
  to,
  type = "submit",
  disabled = false,
}: ButtonTypes) {
  const Button = (
    <button
      onClick={() => {
        if (onClick) onClick();
      }}
      type={type}
      disabled={disabled}
      style={{
        ...(height && { height }),
        ...(fontSize && { fontSize }),
        ...(width && { width }),
        border: border ? `${border}` : "none",
        borderRadius: `${borderRadius}px`,
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
        cursor: disabled ? "not-allowed" : "pointer",
      }}
      className={`${className}  btn${hover} background-color-${
        disabled ? 12 : backgroundColor
      } text-color-${color} border-color-${borderColor} `}
    >
      <div className="btn_content">
        {prefix && prefix}
        {loading ? (
          <CircularProgress color="inherit" />
        ) : (
          <p style={{ ...(fontSize && { fontSize }) }}> {children}</p>
        )}

        {suffix && suffix}
      </div>
    </button>
  );
  return to ? <Link to={to}>{Button}</Link> : Button;
}
