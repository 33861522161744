import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";




export const getCorrectionInfo = createAsyncThunk(
   "dashboard/getCorrectionInfo",
   async ({classId,query}:{classId:string,query:string}) => {
     let data;
     try {
       const response = await axios.get(`/student-dashboards/resent-correction/${classId}${query}`);
       data = await response.data;
       if (response.status === 200) {
         return data;
       }
       throw new Error(response.statusText);
     } catch (err) {
       return Promise.reject(err.message ? err.message : data?.message);
     }
   }
 );

 export const getDashboardInfo = createAsyncThunk(
  "dashboard/getDashboardInfo",
  async ({classId}:{classId:string}) => {
    let data;
    try {
      const response = await axios.get(`/student-dashboards/getInfo/${classId}
      `);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getDashboardMarkSheet = createAsyncThunk(
  "dashboard/getDashboardMarkSheet",
  async ({sessionId,sectionId,classId}:{sessionId:string,sectionId:string,classId:string}) => {
    let data;
    try {
      const response = await axios.get(`/student-dashboards/student-markSheet/${sessionId}/${sectionId}/${classId}
      `);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getStudentStatistics = createAsyncThunk(
  "dashboard/getStudentStatistics",
  async ({classId,sectionId}:{classId:string,sectionId:string}) => {
    let data;
    try {
      const response = await axios.get(`/student-dashboards/studentStatistics/${classId}/${sectionId}`);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);