import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";



export const getSessions = createAsyncThunk(
   "session/getSessions",
   async () => {
     let data;
     try {
       const response = await axiosInstance.get(`/sessions`);
       data = await response.data;
       if (response.status === 200) {
         return data;
       }
       throw new Error(response.statusText);
     } catch (err) {
       return Promise.reject(err.message ? err.message : data?.message);
     }
   }
 );