import { useState, ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
// components
import { ReactComponent as Loader } from "src/assets/logo/ixamee.svg";
//
import Login from "../pages/LoginPage";
import { useAuthContext } from "./useAuthContext";
import { setAccessSession, setRefreshSession } from "./utils";

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized } = useAuthContext();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  if (!isInitialized) {
    return (
      <div className='auth_loader'>
        <Loader />
      </div>
    );
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    setAccessSession(null);
    setRefreshSession(null);
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
