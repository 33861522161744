import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

export const getSubjects = createAsyncThunk(
  "subject/getSubjects",
  async (classId: string) => {
    let data;
    try {
      const response = await axiosInstance.get(`students/subjects/${classId}`);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
