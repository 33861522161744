import { createSlice } from "@reduxjs/toolkit";

export interface Class {
  _id: string;
  name: string;
}
interface Classes {
  activeClass: Class | null;
  classes: Class[] | null;
}

export const initialState: Classes = {
  activeClass: null,
  classes: null,
};

export const classesSlice: any = createSlice({
  name: "classes",
  initialState,
  reducers: {
    setInitClasses: (state, action) => {
      state.activeClass = action.payload[0];
      state.classes = action.payload;
      localStorage.setItem("class", JSON.stringify(action.payload[0]));
    },
    setActiveClass: (state, action) => {
      state.activeClass = action.payload;
    },
    setClasses: (state, action) => {
      state.classes = action.payload;
    },
  },
});

export const { setInitClasses, setActiveClass, setClasses } =
  classesSlice.actions;

export const reducer = classesSlice.reducer;

export default classesSlice;
