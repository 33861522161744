export function getFirstLetters(sentence:String) {
    const words = sentence.split(" ");
    let firstLetters = "";
  
    for (let i = 0; i < Math.min(words.length, 3); i++) {
      const word = words[i];
      firstLetters += word.charAt(0);
    }
  
    return firstLetters;
  }